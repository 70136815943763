import React, { useState } from "react";
import "../assets/css/Home.css";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import { Modal } from "react-bootstrap";
import BookingModal from "../components/BookingModal";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export default ({ data }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <SEO/>
      <Layout transparent>
        <div className="home-background">
          <div className="home-background-image">
          </div>
          <div className="container">
            <Container className="p-2">
              <Row>
                <Col sm={12} md={12} lg={12} className="p-4">
                  <h2 className="pt-4">SASS Holdings where engineering innovation and expertise meet to provide investment in European infrastructure and businesses</h2>
                  <div>
                    <p className="p-4">
                    At SASS Holdings, we are committed to transforming the investment landscape by focusing on projects that not only drive economic growth but also
                     pave the way for a sustainable future. With our focus on engineering projects, infrastructure development, and businesses, we bring together the
                      power of capital and the brilliance of innovation to create opportunities that are not just profitable, but also transformational.
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={12} className="p-2" id='about-us'>
                  <h2 className="">Why Choose Us</h2>
                  <div>
                    <ol>
                      <li>
                      Engineering Expertise: Our team of industry experts possess the knowledge and experience to evaluate, invest in, and oversee engineering businesses and projects
                      </li>
                      <li>
                      Infrastructure Visionaries: We believe that infrastructure forms the backbone of any thriving society. That's why we invest in projects that enhance connectivity, sustainability, and the overall quality of life
                      </li>
                      <li>
                      Business Acumen: Our dedicated team of business analysts and strategists identify opportunities in various sectors, ensuring that your investments are placed in thriving enterprises poised for growth
                      </li>
                      <li>
                      Sustainability Focus: We are committed to promoting projects that not only generate returns but also contribute to a greener, more sustainable future
                      </li>
                    </ol>
                  </div>
                  <Img fluid={data.constructionsite.childImageSharp.fluid} />
                </Col>


                <Col sm={12} md={6} lg={12} className="p-4">
                  <div>
                    <div>
                      <h3>Our Services</h3>
                      <ul>
                        <li>
                        Investment Consultation: Rely on our experts to guide you through the intricacies of investment, offering customised solutions to meet your financial objectives.
                        </li>
                        <li>
                        Project Selection: Our rigorous selection process ensures that we invest in projects with the highest potential for success and sustainability.
                        </li>
                        <li>
                        Asset Management: Once invested, we provide meticulous asset management, ensuring your portfolio grows and evolves over time.
                        </li>
                        <li>
                        Strategic Partnerships: We actively seek partnerships and collaborations that enhance the value and impact of our investments.
                        </li>
                      </ul>
                      <h3>Our Mission</h3>
                        <p>At SASS Holdings, our mission is to blend financial acumen with engineering and infrastructure excellence to unlock the full potential of businesses and projects. We aim to drive sustainable progress, foster innovation, and empower communities through our investments.
                            Join us in reshaping the future of engineering projects, infrastructure development, and businesses. To understand more about what we do and how we can work with you, use our contact form to reach a member of our team. 
                        </p>
                    </div>
                    <Img fluid={data.guylookingatmachine.childImageSharp.fluid} />
                    <h3>Contact</h3>
                    <p>If you would like to reach out to us, please use the contact form at the top of this page. We are currently looking for exciting investment opportunities in 2023 and 2024 in Europe one.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="text-center p-3 white-container">Contact us</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookingModal />
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
};
export const query = graphql`
  query {
    constructionsite: file(relativePath: { eq: "construction-site.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    guylookingatmachine: file(relativePath: { eq: "guy-looking-at-machine.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    constructionzone: file(relativePath: { eq: "sassholdings.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
